$separator-color-light: #f3f3f3;
$separator-color: #d7d7d7;
$background-color: white;
$foreground-color : white;
$input-background: white;
$cream-background: #EFEEE5;

$dark-btn-background: #131113;
$light-btn-background: #00000029;
$button-text-color: #fff;

$theme-color-1: #d85b3f;
$theme-color-2: #E4E1D2;
$theme-color-3: #DDBCA5;
$theme-color-4: #2d608c;
$theme-color-5: #2D608C99;
$theme-color-6: #8E8B7E;
$theme-color-7: #993300;
$theme-color-8: #e57038;

$primary-color: #827F28;
$primary-color-1: #c1bf85;
$primary-color-2: #a9a657;
$primary-color-3: #5C5913;
$secondary-color: #827F28;
$muted-color: #909090;

$gradient-color-1 : #e0bf5c;
$gradient-color-2 : #c7a951;
$gradient-color-3 : #d3b455;

$lp-bg-color-1 : #74622d;
$lp-bg-color-2 : #9c8339;
$lp-bg-color-3 : #dab74f;
$lp-bg-color-4 : #e6c154;

$shadow-offsets-top : 1 3 10 14 19;
$shadow-blurs-top: 1.5 5 10 14 19;
$shadow-opacities-top: 0.04 0.1 0.19 0.25 0.3;

$shadow-offsets-bottom : 1 3 6 10 15;
$shadow-blurs-bottom: 3 6 6 5 6;
$shadow-opacities-bottom: 0.04 0.1 0.2 0.22 0.22;

$logo-path: "../../logos/mobile.png";
$logo-path-mobile: "../../logos/mobile.png";

$lp-logo-path-pinned: "../../logos/mobile.png";
