/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';
@import "themes/vien.light.variables.zzzing";

// Generated using https://ionicframework.com/docs/theming/color-generator
:root {
  --ion-color-primary: #d85b3f;
  --ion-color-primary-rgb: 216,91,63;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255,255,255;
  --ion-color-primary-shade: #be5037;
  --ion-color-primary-tint: #dc6b52;

  --ion-color-secondary: #827F28;
  --ion-color-secondary-rgb: 130,127,40;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255,255,255;
  --ion-color-secondary-shade: #727023;
  --ion-color-secondary-tint: #8f8c3e;

  --ion-color-tertiary: #2d608c;
  --ion-color-tertiary-rgb: 45,96,140;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255,255,255;
  --ion-color-tertiary-shade: #28547b;
  --ion-color-tertiary-tint: #427098;

  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45,211,111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255,255,255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255,196,9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0,0,0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235,68,90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255,255,255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34,36,40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255,255,255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146,148,156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255,255,255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244,245,248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0,0,0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;
}

ion-content {
  --color: $primary-color;
  --padding-bottom: var(--ion-safe-area-bottom);
}

ion-content.header {
  --background: #{$background-color};
  --padding-top: 15px;
  --padding-bottom: calc(var(--ion-safe-area-bottom) + 50px);
}

ion-header {
  padding-left: 15px;
  padding-right: 15px;
  background: #{$background-color};
}

ion-header, ion-toolbar {
  i {
    color: #{$theme-color-4}
  }
}

.header-button {
  background: white;
  width: 38px !important;
  height: 38px !important;
  border-radius: 50%;

  i {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $theme-color-1;
  }
}

ion-toolbar {
  --background: none;
  padding-top: max(var(--ion-safe-area-top, 0), 5px) !important;
  padding-bottom: max(var(--ion-safe-area-top, 0), 0) !important;
}

ion-header {

  -moz-box-shadow: 0 2px 2px rgba(160, 159, 159, 0.4);
  -webkit-box-shadow: 0 2px 2px rgba(160, 159, 159, 0.4);
  box-shadow: 0 2px 2px rgba(160, 159, 159, 0.4);
  padding-bottom: 2px;

  p {
    font-size: 14px;
  }

  h1.light-header {
    margin: 0;
    font-size: 18px;
    color: $theme-color-1;
  }

  h2 {
    margin: 0;
    font-size: 20px;
    font-weight: 500;
    color: $theme-color-1;
  }
}


ion-title {
  padding-left: 20px;
  padding-right: 20px;
  top: 5px
}

.scroll-content {
  padding-bottom: 0 !important;
}

ion-tab-bar {
  padding-left: 20px;
  padding-right: 20px;
}

ion-segment {
  background: none;
}

ion-searchbar {
  --border-radius: 20px !important;
  --box-shadow: none !important;
  --color: #{$primary-color} !important;
  --icon-color: #{$primary-color} !important;
  --background: white !important;
}


ion-fab-button {
  --background: rgba(255, 255, 255, 0.8);
  --color: #{$theme-color-1};
  width: 48px;
  height: 48px;

  i {
    font-size: 20px;
  }
}

.swiper-pagination-bullets {
  bottom: 38px !important;
}

.swiper-pagination-bullet {
  border: white 2px solid !important;
  background: none !important;
  opacity: 1 !important;
  width: 14px;
  height: 14px;
}

.swiper-pagination-bullet-active {
  border: white 2px solid !important;
  background: white !important;
  opacity: 1 !important;
}

ion-datetime {
  border-bottom: $theme-color-2 1px solid;
}

.picker-toolbar.sc-ion-picker-md {
  justify-content: center;
}

.picker-button.sc-ion-picker-md, .picker-button.ion-activated.sc-ion-picker-md,.picker-button.sc-ion-picker-ios, .picker-button.ion-activated.sc-ion-picker-ios {
  color: $primary-color !important;
}

.picker-prefix, .picker-suffix, .picker-opt.picker-opt-selected {
  color: $primary-color !important;
  font-weight: 600;
  font-size: 24px;
}

.open_picker, ion-picker {
  touch-action: none !important; // Prevents 'Ignored attempt to cancel a touchstart event' error
}

.popover-content {
  background: none !important;
}

ion-select {
  padding-left: 12px;
}

.popover-content {
  --min-width: 80%;
}

ion-select::part(text), ion-select::part(placeholder) {
  border-bottom: 1px solid $separator-color;
}

ion-radio::part(mark) {
  --color-checked: #{$theme-color-1};
}

ion-textarea {
  border: 1px solid var(--theme-color-2);
  border-radius: var(--card-radius);

  .textarea-wrapper {
    height: 100%;

    textarea {
      height: 100%;
      padding: 10px !important;
    }
  }
}

ion-spinner{
  color: var(--theme-color-1) !important;
}

.loading-content {
  color: var(--color-muted) !important;
}

ion-card {
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: var(--card-radius);

  img{
    object-fit: cover;
  }
}

ion-card.blue-shadow {
  ion-card-title {
    --color: #{var(--theme-color-4)} !important;
  }
}
